@charset 'utf-8';

@import "~bulma/sass/utilities/functions";

$list-item-border: none;
$list-shadow: none;

// Import Bulma now
@import "~bulma/bulma";
// Extend with rbx fixes
@import "~rbx/rbx";
